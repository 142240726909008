aside {
  overflow-y: auto;
  height: 100%;

  h5 {
    font-size: 25px;
    color: rgba(50, 38, 98, 1);
    display: inline-block;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(50, 38, 98, 0.5);
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      font-size: 18px;
      color: rgba(50, 38, 98, 1);
      padding: 10px;

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: inherit; // Inherit color from parent li

        &.active-route {
          color: #322662; /* Active route color */
          font-weight: bold;
        }

        &:hover {
          color: #322662; /* Hover color for text */

          svg path {
            fill: #322662; /* Hover color for icons */
            background-color: #322662;
          }
        }
      }

      svg {
        width: 24px;
        height: 24px;
        margin: 0 10px;
        path {
          transition: fill 0.2s ease-in-out;
        }
      }
    }
  }
}
