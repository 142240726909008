.numbers-section {
  &__content {
    background: rgba(48, 36, 95, 0.6);
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
  h2 {
    font-size: 20px;
    color: rgb(250, 250, 250) !important;
    /* From https://css.glass */
    /* From https://css.glass */
    background: rgba(48, 36, 95, 0.7);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: inline-block;
    padding: 10px 20px;
    border-radius: 15px;
    margin-bottom: 60px;
  }
}

.cercle-bg {
  background-color: #fff;
  border-radius: 50%;
  width: 160px; /* Default size for smaller screens */
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgba(50, 38, 98, 1);
  padding: 0;

  .cyrcle {
    border: 5px solid rgba(50, 38, 98, 0.541);
    border-radius: 50%;
    width: 98%;
    height: 98%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
      width: 30px;
      height: 30px;
      margin-bottom: 10px;
    }

    svg path {
      fill: rgba(50, 38, 98, 1);
      font-size: 10px;
    }

    h3 {
      font-size: 25px;
      margin: 0;
      font-weight: 700;
      color: rgba(50, 38, 98, 1) !important;
    }

    p {
      font-size: 14px;
      margin: 0;
      font-weight: 700;
    }
  }
}
