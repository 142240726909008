.register {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgba(50, 38, 98, 1);
  color: #fff;
  padding-top: 4rem;

  .container {
    margin-bottom: 2rem;
  }

  .underline_link {
    border-bottom: 1px solid #fff;
    padding: 0;
    border-radius: 0;
  }

  form {
    background-color: #fff;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    border-radius: 10px;
    width: 100%; /* Ensures form stretches to available width */

    .PhoneInput {
      padding: 0;
      border: 0;

      input {
        border: none;
      }

      .PhoneInputCountrySelectArrow {
        display: none;
      }

      .PhoneInputCountry {
        margin-left: 10px;
      }
    }
    h2 {
      color: rgba(50, 38, 98, 1);
      text-transform: uppercase;
    }
    .register-google {
      display: flex;
      align-items: center;
      justify-content: center;

      color: rgba(50, 38, 98, 1);

      p {
        font-size: 20px;
        font-weight: 500;
        margin: 0;
        margin-left: 10px;
      }
    }
    .btn {
      background-color: rgba(50, 38, 98, 1);
      width: 100%;
      padding: 10px;
    }

    .form-group {
      width: 100%;
      input {
        width: 100%; /* Make input fields take full width */
        background-color: rgba(230, 230, 230, 1);
        padding-left: 50%; /* Push the placeholder text toward the center */
        text-align: left; /* Ensure the text remains left-aligned when typing */
        border-radius: 5px;
        padding: 10px;

        &::placeholder {
          text-align: center; /* Center-align placeholder text */
          /* Adjust placeholder to center */
          color: rgba(50, 38, 98, 1); /* Optional: Placeholder text color */
        }
      }
    }
  }
}

.footer {
  margin-top: auto; /* Makes sure the footer sticks to the bottom */
  background-color: rgba(50, 38, 98, 1);
  color: #fff;
  padding: 2rem;
  text-align: center;
}
