.bot-info {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.form-header {
  background-color: #326754;
  color: white;
  padding: 1rem;
  border-radius: 8px 8px 0 0;
}

.form-layout {
  padding: 1.5rem;
}

.node-container {
  margin-bottom: 1.5rem;
  
  .node-content {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 1rem;
  }
}

.button-item, .section-item, .option-item {
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  margin-bottom: 1rem;
}

.border-dashed {
  border: 2px dashed #dee2e6;
  border-radius: 6px;
  background-color: #f8f9fa;
  cursor: pointer;
  
  &:hover {
    border-color: #326754;
  }
}

.btn-primary {
  background-color: #326754;
  border-color: #326754;
  
  &:hover {
    background-color: darken(#326754, 10%);
    border-color: darken(#326754, 10%);
  }
}

.btn-danger {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

h4 {
  color: #326754;
  margin-bottom: 1rem;
}

.form-control {
  &:focus {
    border-color: #326754;
    box-shadow: 0 0 0 0.2rem rgba(50, 103, 84, 0.25);
  }
}

.node-type-selector {
  .btn-outline-primary {
    color: #326754;
    border-color: #326754;
    
    &:hover {
      background-color: #326754;
      color: white;
    }
  }
}

.option-item {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
}