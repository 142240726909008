.CircularProgressbar-text {
  font-size: 10px !important;
}

.bar-wrapper {
  background-color: #322662;
  border-radius: 20px;
  padding: 20px;
  background-image: url("../../assets/wave-bg.svg");
}
