.smart-bot-header {
  margin-bottom: 2rem;
  
  h2 {
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .description {
    color: #666;
  }
}
.delete-btn{
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 0.5rem;
}
.bots-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.bot-card {
  background: white;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 1rem;

  .bot-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;

    .status-badge {
      padding: 0.25rem 1rem;
      border-radius: 20px;
      font-size: 0.875rem;

      &.active {
        background: #28a745;
        color: white;
      }

      &.inactive {
        background: #dc3545;
        color: white;
      }
    }

    .menu-btn {
      background: none;
      border: none;
      color: #666;
      cursor: pointer;
      padding: 0.5rem;
    }
  }

  .bot-info {
    margin-bottom: 1rem;

    .bot-name {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 0.5rem;

      .bot-icon {
        width: 24px;
        height: 24px;
        color: #326754;
      }
    }

    .whatsapp-number {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #666;

      i {
        color: #25D366;
      }
    }
  }

  .bot-actions {
    display: grid;
    grid-template-columns:  1fr;
    gap: 0.5rem;

    .action-btn {
      background: #f8f9fa;
      border: 1px solid #dee2e6;
      border-radius: 6px;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      cursor: pointer;

      &:hover {
        background: #e9ecef;
      }

      i {
        color: #326754;
      }
    }
  }
}

.create-bot-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
  border: 2px dashed #dee2e6;
  border-radius: 8px;
  padding: 2rem;
  text-decoration: none;
  color: #326754;
  height: 100%;
  transition: all 0.3s;

  &:hover {
    background: #e9ecef;
    border-color: #326754;
  }

  .create-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 1rem;
    color: currentColor;
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;

  .pagination-controls {
    display: flex;
    align-items: center;
    gap: 1rem;

    .nav-btn {
      background: none;
      border: none;
      color: #326754;
      cursor: pointer;

      &:disabled {
        color: #dee2e6;
        cursor: not-allowed;
      }
    }
  }
}

.loading-spinner {
  display: flex;
  justify-content: center;
  padding: 3rem 0;
}

.bot-menu {
  position: relative;

  .menu-btn {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 0.5rem;
    
    &:hover {
      color: #333;
    }
  }

  .menu-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    z-index: 1000;
    min-width: 160px;
    
    .menu-item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 1rem;
      border: none;
      background: none;
      width: 100%;
      text-align: right;
      color: #333;
      text-decoration: none;
      cursor: pointer;
      
      &:hover {
        background: #f8f9fa;
      }
      
      &.delete {
        color: #dc3545;
        
        &:hover {
          background: #dc3545;
          color: white;
        }
      }
      
      i {
        width: 16px;
        text-align: center;
      }
    }
  }
}

// Add click-outside handling
body.has-menu-open {
  .bot-menu .menu-dropdown {
    display: block;
  }
} 