.plans {
  h2 {
    font-size: 20px;
    color: rgb(250, 250, 250);
    /* From https://css.glass */
    /* From https://css.glass */
    background: rgba(48, 36, 95, 0.7);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: inline-block;
    padding: 10px 20px;
    border-radius: 15px;
    margin-bottom: 1rem;
  }

  &__wrapper {
    height: 800px;
    color: rgb(250, 250, 250);
    /* From https://css.glass */
    /* From https://css.glass */
    background: rgba(48, 36, 95, 0.7);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    //     border-top: 30px solid rgba(71, 55, 134, 0.7);
    //     border-bottom: 30px solid rgba(71, 55, 134, 0.7);

    .plans__slider {
      background-color: rgba(48, 36, 95, 0.7);
      height: 90%;
      padding: 20px;
    }

    .plan {
      text-align: center;
      background-color: #fff;
      color: #000;
      width: 346px !important;
      margin-top: 2rem;
      height: auto;
      position: relative;
      border-radius: 15px;

      .btn {
        padding: 13px 102px 13px 102px;
        color: rgba(71, 55, 134, 1);
        border: 3px solid rgba(71, 55, 134, 1);
        border-radius: 20px;
      }

      .price {
        position: absolute;
        background-color: rgba(71, 55, 134, 1);
        color: #fff;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        font-size: 12px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border: 2px solid #fff;
        top: 15%;
        right: 20%;
        transform: translate(-50%, -20%);

        p {
          margin: 0;
        }
      }
      ul {
        margin-top: 7rem;
        list-style: none;
        display: inline-block;
        padding: 0;
        li {
          border-bottom: 1px solid rgba(71, 55, 134, 0.7);
          margin-bottom: 15px;
          p {
            text-align: center;
            color: rgba(71, 55, 134, 1);
          }
        }

        li:last-child {
          border-bottom: 0px solid rgba(71, 55, 134, 0.7);
        }
      }

      .header {
        background-color: rgba(71, 55, 134, 1);
        color: #fff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 2.4rem;

        h3 {
          font-size: 15px;
        }
      }
    }
  }
}

.slick-dots {
  bottom: -25px;
}
