table {
    width: 100%;
    border-collapse: collapse;
}

thead {
    border-bottom: 2px solid black;
    th {
        // padding: 1rem;
        text-align: right;
        max-width: 25%;
    }
}

tbody {
    tr {
        border-bottom: 0.5px solid rgba(134, 130, 130, 0.673);
    }
    
    td {
        // padding: 1rem;
        vertical-align:middle;
        word-break: break-word;
        width: 25% !important;
        white-space: pre-wrap;
        text-align: center;
        text-overflow:unset;
        overflow: hidden;
        height: 10vh;
    }
}

@media screen and (max-width: 768px) {
    table {
        display: block;
        overflow-x: auto;
        // white-space: nowrap;
        
        td {
            // min-width: 120px;
            font-size: 10px !important;
            button{
                padding: 3px !important;
                font-size: 8px !important;
                margin: 0 !important;
            }
        }
    }
}