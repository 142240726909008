.how-section {
  h2 {
    color: rgba(50, 38, 98, 1);
  }

  .shap {
    clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
    background-color: rgba(50, 38, 98, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    margin: 3rem;

    img {
      width: 50px;
    }
  }

  h5 {
    color: rgba(50, 38, 98, 1);
    font-size: 20px;
    text-align: center;
    font-weight: 700;
  }
}
