.new-text {
  .first {
    font-size: 25px;
    color: rgba(108, 99, 255, 1);
  }

  h2 {
    font-size: 30px;
    color: rgb(255, 255, 255);
  }

  .second {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.5);
  }

  button {
    padding: 10px 25px 10px 25px;
    border-radius: 15px;
    transition: all ease-in-out 0.5s;
  }

  .primary-btn {
    background-color: rgba(50, 38, 98, 1);
    color: #fff;
    margin-left: 20px;
  }

  .primary-btn:hover {
    background-color: transparent;
    color: #000;
    margin-left: 20px;
  }
}
